// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export const environment = {
  production: false,
  auth0: {
    clientId: 'bYWuMkUoO0wMql5FxT83MdKSgRgTEcK8', // DEV client ID - do not change
    domain: 'linuxfoundation-dev.auth0.com',
    audience: 'https://api-gw.dev.platform.linuxfoundation.org/',
    redirectUri: `${window.location.origin}/auth`,
  },
  datadogEnv: '',
  traceOrigins: [],
  graphqlUrl: 'http://localhost:5000',
  subscriptionsWebsocketUrl: 'ws://localhost:5000',
  lfxAcsUICDN: 'https://lfx-acs-ui.dev.platform.linuxfoundation.org',
  lfxHeaderCDN: 'https://cdn.dev.platform.linuxfoundation.org/lfx-header-v2.js',
  lfxFooterCDN: 'https://cdn.dev.platform.linuxfoundation.org/lfx-footer.js',
  lfxHeader: {
    pccLink: 'https://pcc.dev.platform.linuxfoundation.org/',
    docsLink: 'https://docs.linuxfoundation.org/lfx/security',
    supportLink:
      'https://jira.linuxfoundation.org/plugins/servlet/theme/portals/category/4',
    faqLink: 'https://docs.linuxfoundation.org/lfx/security/lfx-security-faqs',
  },
  SECURITY_SERVICE:
    'https://api-gw.dev.platform.linuxfoundation.org/security-service/v2',
};
