// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { environment as defaultEnvironment } from './environment.defaults';

export const environment = {
  ...defaultEnvironment,
  datadogEnv: 'development',
  traceOrigins: ['https://security.dev.platform.linuxfoundation.org'],
  auth0: {
    domain: 'linuxfoundation-dev.auth0.com',
    clientId: 'bYWuMkUoO0wMql5FxT83MdKSgRgTEcK8', // DEV client ID - do not change
    redirectUri: `${window.location.origin}/auth`,
    audience: 'https://api-gw.dev.platform.linuxfoundation.org/',
  },
  graphqlUrl: 'https://lfx-security-bff.dev.platform.linuxfoundation.org',
  subscriptionsWebsocketUrl:
    'wss://lfx-security-bff.dev.platform.linuxfoundation.org',
  lfxAcsUICDN: 'https://lfx-acs-ui.dev.platform.linuxfoundation.org',
  lfxHeaderCDN: 'https://cdn.dev.platform.linuxfoundation.org/lfx-header-v2.js',
  lfxFooterCDN: 'https://cdn.dev.platform.linuxfoundation.org/lfx-footer.js',
  lfxHeader: {
    pccLink: 'https://pcc.dev.platform.linuxfoundation.org/',
    docsLink: 'https://docs.linuxfoundation.org/lfx/security',
    supportLink:
      'https://jira.linuxfoundation.org/plugins/servlet/theme/portals/category/4',
    faqLink: 'https://docs.linuxfoundation.org/lfx/security/lfx-security-faqs',
  },
  SECURITY_SERVICE:
    'https://api-gw.dev.platform.linuxfoundation.org/security-service/v2',
};
