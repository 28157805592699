// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { User } from '@auth0/auth0-spa-js';
import { headerConstants } from '@config';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from '@environment';
import * as FullStory from '@fullstory/browser';
import { first } from 'rxjs/operators';

@Component({
  selector: 'lfx-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  currentUrl = window.location.href;

  constructor(private authService: AuthService) {
    this.setLFxHeaderScript();
  }

  ngOnInit(): void {
    FullStory.init({ orgId: 'MDAHR', devMode: !environment.production });

    const lfxHeader: any = document.getElementById('lfx-header');

    if (lfxHeader) {
      lfxHeader.docslink = environment.lfxHeader.docsLink;
      lfxHeader.supportlink = environment.lfxHeader.supportLink;
      lfxHeader.faqlink = environment.lfxHeader.faqLink;
      lfxHeader.logouturl = window.location.origin;
      lfxHeader.callbackurl = window.location.origin;
      lfxHeader.userefreshtoken = true;
      lfxHeader.links = [
        {
          title: headerConstants.secureMyProject,
          url: environment.lfxHeader.pccLink,
        },
      ];

      lfxHeader.beforeLogout = function () {
        window.localStorage.removeItem('accessIdentity');
      };

      datadogRum.init({
        applicationId: '53abbb04-cb47-48fe-9450-e35d3db3fc3f',
        clientToken: 'pub64b650fad84c12d46bf86edb17dbf498',
        site: 'datadoghq.com',
        service: 'lfx-security',
        env: environment.datadogEnv,
        traceSampleRate: environment.datadogEnv ? 100 : 0,
        sessionSampleRate: environment.datadogEnv ? 100 : 0,
        sessionReplaySampleRate: environment.datadogEnv ? 20 : 0,
        telemetrySampleRate: environment.datadogEnv ? 100 : 0,
        trackUserInteractions: true,
        allowedTracingUrls: environment.traceOrigins,
        defaultPrivacyLevel: 'allow',
        version: '',
      });

      this.authService.user$.subscribe((data: User | undefined | null) => {
        if (environment.datadogEnv) {
          // Added here because few of the pages user login not required.
          console.log('Datadog ENV : ' + environment.datadogEnv);
          datadogRum.startSessionReplayRecording();
        }

        if (data) {
          lfxHeader.authuser = data;
          console.log(data);
          // Set user session to datadog.
          datadogRum.setUser({
            id: data['https://sso.linuxfoundation.org/claims/username'],
            email: data.email,
            name: data.name,
          });

          FullStory.identify(data.nickname as string, {
            displayName: data.name,
            email: data.email,
          });
        }
      });
    }

    this.handleBadHandleLogin();

    // Add event listener to support tooltip on mobile view.
    document.body.addEventListener('touchstart', function () {
      document.body.classList.add('touched');
    });
  }

  async handleBadHandleLogin() {
    if (this.currentUrl.includes('code=')) {
      this.authService.isAuthenticated$.pipe(first()).subscribe(isLoggedIn => {
        if (!isLoggedIn) {
          this.authService.loginWithRedirect();
        }
      });
    }
  }

  private setLFxHeaderScript() {
    const headerScript = document.createElement('script');

    headerScript.setAttribute('src', environment.lfxHeaderCDN);
    headerScript.setAttribute('async', 'true');
    document.head.appendChild(headerScript);
  }
}
